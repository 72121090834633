<template>
  <el-card class="box-card"
           v-loading="loading">
    <ul>
      <li v-for="item in contactList"
          :key="item.title">
        <div>
          <img class="icon"
               :src="item.icon"
               alt="icon">
          <h6>{{item.title}}</h6>
          <span v-show="item.weChat"
                class="gray-txt we-chat">微信号：{{item.weChat}}<span class="copy"
                  v-clipboard:copy="item.weChat"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError">复制</span></span>
          <span class="gray-txt thirdTxt">{{item.detail}}</span>
          <span class="gray-txt thirdTxt">{{item.thirdTxt}}</span>
        </div>
      </li>
    </ul>
  </el-card>
</template>

<script>
import api from '@/api/index'
export default {
  data () {
    return {
      contactList: [],
      loading: true
    }
  },
  created () {
    this.$store.commit("layouts/setPathList", [{ name: '首页', path: '/home' }, { name: '联系我们' }]);
    this.$store.commit("layouts/changeBreadcrumb", true)
    this._initState()
  },
  beforeDestroy () {
    this.$store.commit("layouts/changeBreadcrumb", false)
  },
  methods: {
    async _initState () {
      const { status_code, message } = await api.contactApi()
      if (status_code === 200) {
        this.contactList.push({
          icon: require('img/sys_ operation.png'),
          title: '对接运营',
          weChat: message.wechat,
        })
        this.contactList.push({
          icon: require('img/service_phone.png'),
          title: '客服',
          weChat: message.official_wechat,
          detail: '电话号：' + message.support_number,
          thirdTxt: '工作时间：周一至周六 12:00至22:00',
        })
        this.loading = false
      }
    },
    onCopy () {
      this.$message({
        message: '复制成功',
        type: 'success'
      })
    },
    onError () {
      this.$message({
        message: '复制失败',
        type: 'error'
      })
    }
  }
}
</script>

<style lang="less" scoped>
li {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 30px;
}
li + li {
  margin-top: 30px;
}
li:last-child {
  border-bottom: none;
}
div {
  padding-left: 29px;
  margin-top: 10px;
}
p {
  color: #333333;
  margin-top: 15px;
}
h6 {
  font-size: 18px;
}
p {
  font-size: 16px;
}
.icon {
  display: block;
  width: 19px;
  height: 19px;
  position: absolute;
  left: 0;
  top: 0;
}
.gray-txt,
.copy {
  font-size: 14px;
  color: #666666;
}
.copy {
  color: #ff3b00;
  margin-left: 14px;
  cursor: pointer;
}
.thirdTxt,
.we-chat {
  display: block;
  margin-top: 15px;
}
.QRcode {
  display: block;
  width: 88px;
  height: 88px;
  background: #d8d8d8;
  margin-left: 5px;
}
.sweep {
  font-size: 14px;
  color: #333333;
  display: inline-block;
  margin-top: 5px;
}
</style>